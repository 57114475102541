import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';
import { breakpoint, pxToRem, Theme } from 'src/theme';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    background: 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #F7F7F7',
    [breakpoint.down('md')]: {
      background: 'linear-gradient(197.95deg, #13266D 25.76%, #00B6B6 103.8%), #F7F7F7',
    },
  },
});

interface Props {
  showShape?: boolean,
}

const rotation = 29;

const PricingContactSection: React.FC<Props> = ({ showShape = false }) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  return (
    <SectionContainer className={classes.container}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-10 lg:gap-x-20 items-center">
        <div
          className="p-0 sm:p-8"
          style={{
            padding: showShape ? '12%' : undefined,
          }}
        >
          <div className="relative" style={{ maxWidth: pxToRem(500) }}>
            {showShape && (
              <div
                style={{
                  borderRadius: 20,
                  backgroundColor: theme.palette.secondary.ORANGE,
                  width: '87%',
                  height: '98%',
                  transform: `rotate(${90 + rotation}deg)`,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              />
            )}
            <StaticImage
              src="https://assets.bizwise.com/landing-ui/sections/9.jpg"
              width={600}
              alt="Programmer woman at computer"
              style={{
                borderRadius: 20,
                filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
              }}
            />
          </div>
        </div>
        <div>
          <Typography variant="h2" color="white" className="mb-6 text-center sm:text-left mx-auto sm:mx-0" style={{ maxWidth: pxToRem(580) }}>
            Have a Question, Comment, or Concern?
          </Typography>
          <Button href="/contact/" variant="filled" color="teal" className="w-full md:w-auto">
            Contact us
          </Button>
        </div>
      </div>
    </SectionContainer>
  );
};

export default PricingContactSection;
